var bus = new Vue();

bus.$on('admin-nav', function(data) {
  app.$data.isAdmin = data;
});

var shared = {
  addGame: { submitLabel: 'Add Game', title: 'Add a Game' },
  updateGame: { submitLabel: 'Update Game' },
  searchTimeout: null,
  keyTimeout: 500,
  tempPassword: '',
  data: {
    days: [
      { _id: 0, title: 'Pre E3' },
      { _id: 1, title: 'Day 1' },
      { _id: 2, title: 'Day 2' },
      { _id: 3, title: 'Day 3' },
      { _id: 4, title: 'Day 4' },
      { _id: 5, title: 'Day 5' }
    ],
    exclusives: [
      { _id: 1, title: 'PS4 Exclusive' },
      { _id: 2, title: 'XBox One Exclusive' },
      { _id: 3, title: 'Switch Exclusive' },
      { _id: 5, title: '3DS Exclusive' },
      { _id: 4, title: 'PC Exclusive' }
    ],
    vr: [
      { _id: 1, title: 'Oculus Rift Only' },
      { _id: 2, title: 'HTC Vive Only' },
      { _id: 3, title: 'PlayStation VR Only' },
      { _id: 4, title: 'Other VR Only' },
      { _id: 5, title: 'Coming to All VR' }
    ],
    dateFormats: [
      { _id: 1, title: 'Full Date' },
      { _id: 2, title: 'Year and Month' },
      { _id: 3, title: 'Year Only' },
      { _id: 4, title: 'Year and Quarter' }
    ],
    release: [
      { _id: '2017-04-01', title: 'Q2 2017' },
      { _id: '2017-07-01', title: 'Q3 2017' },
      { _id: '2017-10-01', title: 'Q4 2017' },
      { _id: '2018-01-01', title: 'Q1 2018' },
      { _id: '2018-04-01', title: 'Q2 2018' }
    ]
  },
  cssFriendly: function(value) {
    return value.toLowerCase().replace(/\s/g, '-').replace(/^\d/, '');
  },
  getTimestamp: function(date) {
    if(date) return Number(moment(date).format('X'));
    return false;
  },
  getNowTimestamp: function() {
    return Number(moment().format('X'));
  }
};

var dataMixin = {
  methods: {
    updateGames: function() {
      // console.log('games updated', this.activeFilters);

      var vm = this;
      var query = '';

      if(vm.activeFilters && vm.activeFilters.length) {
        var queryVars = [];

        // build the query
        vm.activeFilters.forEach(function(filter) {
          if(filter.filters.length) {
            queryVars.push(filter.name + '=' + filter.filters.join(','));
          }
        });

        query = queryVars.join('&');
      }

      if(vm.search) {
        var sanitized = encodeURIComponent(vm.search);
        query = query ? query + '&s=' + sanitized : 's=' + sanitized;
      }

      if(vm.sort) {
        query = query ? query + '&sort=' + vm.sort : 'sort=' + vm.sort;
      }

      query = query ? '/?' + query : '';

      // console.log('query:', query);

      axios
        .get('/api/games' + query)
        .then(function(response) {
          // console.log(response);
          vm.games = response.data;
        })
        .catch(function(error) {
          // redirect to error page
        });
    }
  }
};


var utilityMixin = {
  methods: {
    closeDropdowns: function() {
      for(var key in this.dropdowns) {
        this.dropdowns[key] = false;
      }
    },

    toggleDropdown: function(target) {

      ga('send', 'event', {
        eventCategory: 'Dropdown',
        eventAction: 'click',
        eventLabel: target
      });

      if(this.dropdowns[target]) {
        this.closeDropdowns();
      } else {
        this.closeDropdowns();
        this.dropdowns[target] = !this.dropdowns[target];
      }
    }
  }
};


var formMixin = {
  methods: {
    errorHandler: function(error) {
      console.log('there was an error', error.response);

      this.error = '';
      this.errors = [];
      this.success = '';

      if(error.response.data && error.response.data.name === 'ValidationError') {
        var validationErrors = error.response.data.errors;

        for(var errorKey in validationErrors) {
          var validationError = validationErrors[errorKey];
          this.errors.push(validationError);
        }

      } else {
        this.error = error.response.data.message;
      }
    }
  }
}


var allRoutesMixin = {

  beforeRouteEnter: function(to, from, next) {
    // console.log(to, from);
    ga('send', 'pageview', to.path);

    axios
      .get('/api/init')
      .then(function(res) {

        if(res.data) {
          next();
        } else {
          next('/install');
        }
      });
  }
};


var addAndEditMixin = {
  data: function() {
    return { now: new Date() };
  },
  props: ['formValues', 'games', 'filterGroups'],
  methods: {

    mapCallback: function(release) {
      return release._id._id;
    },

    updateReleases: function() {
      var vm = this;

      vm.formFields.release = [];

      vm.platforms.forEach(function(platform, i) {
        // console.log(platform);
        if(vm.releases[i]) vm.formFields.release.push(platform);
      });
    },

    updateDateDisplay: function(formatId) {
      this.formFields.date_display = formatId;
    },

    addRelease: function() {
      this.formFields.release.push({
        platform: null,
        date: null
      });
    },

    removeRelease: function(index) {
      this.formFields.release.splice(index, 1);
    },

    addMedia: function() {
      this.formFields.media.push({
        type: null,
        link: null,
        external: false
      });
    },

    removeMedia: function(index) {
      this.formFields.media.splice(index, 1);
    }
  }
};

Vue.directive('tracked', {
  inserted: function(el, binding) {
    var target = $(el);
    target.click(function() {
      // console.log(el, binding);
      ga('send', 'event', {
        eventCategory: 'Outbound Link',
        eventAction: 'click',
        eventLabel: binding.value,
        transport: 'beacon'
      });
    });
  }
});

Vue.directive('menu', {
  inserted: function(el) {
    var target = $(el);
    var className = 'active';

    target.click(function() {
      var isActive = $(this).hasClass(className);

      $('.show-menu').removeClass(className);

      if(isActive) {
        $(this).removeClass(className);
      } else {
        $(this).addClass(className);
      }
    });
  }
});

Vue.directive('submenu', {
  inserted: function(el) {
    var target = $(el);
    var className = 'active';

    target.click(function() {
      $(this).siblings().removeClass(className);
      $(this).addClass(className);
    });
  }
});

Vue.filter('displayTitle', function(value) {
  return value === 'vr' ? 'Virtual Reality' : value;
});

Vue.filter('formattedDate', function(value) {
  return moment.tz(value, moment.tz.guess()).format('MMM Do, YYYY @ h:mm:ss A z');
});

Vue.filter('formatTitles', function(releases) {
  return releases.map(function(release) {
    return release._id.title;
  }).join(', ');
});

Vue.component('countdown', {
  template: '#countdown-template',
  data: function() {
    return {
      conferences: null,
      countdown: null,
      live: null,
      next: null,
      int: null
    };
  },
  methods: {
    countdownTimer: function() {
      var vm = this;
      var conferences = vm.conferences;

      // final conference has not yet concluded
      if(!conferences[conferences.length - 1].end_time) {

        for(var i = 0; i < conferences.length; i++) {
          var conference = conferences[i];
          var now = shared.getNowTimestamp();
          var start = shared.getTimestamp(conference.start_time);
          var end = shared.getTimestamp(conference.end_time);

          if(vm.int) clearInterval(vm.int);

          vm.int = setInterval(function() {
            var diff = moment.utc(conferences[i].start_time).diff(moment.utc());
            var dur = moment.duration(diff);
            var displayedTime = ' - ';
            var sep = ' : '

            displayedTime += dur.days() + sep;
            displayedTime += (dur.hours().toString().length > 1 ? dur.hours() : '0' + dur.hours()) + sep;
            displayedTime += (dur.minutes().toString().length > 1 ? dur.minutes() : '0' + dur.minutes()) + sep;
            displayedTime += (dur.seconds().toString().length > 1 ? dur.seconds() : '0' + dur.seconds());

            vm.countdown = displayedTime;

          }, 1000);

          if((now >= start && !end) || (now >= start && now < end)) {
            vm.next = null;
            vm.live = conference.title + ' Conference';
            break;
          }

          if(now < start) {
            vm.next = conference.title;
            vm.live = null;
            break;
          }
        }
      }
    }
  },
  watch: {
    countdown: function(newVal) {
      // console.log(newVal);
      this.countdownTimer();
    }
  },
  beforeCreate: function() {
    var vm = this;

    axios
      .get('/api/conferences')
      .then(function(res) {
        // console.log(res);
        vm.conferences = res.data;
        vm.countdownTimer();
      });
  }
});

Vue.component('schedule', {
  props: ['conferences'],
  template: '#schedule-template'
});

Vue.component('adsense', {
  template: '#adsense-template',
  props: {
    adClient: {
      type: String,
      required: true
    },
    adSlot: {
      type: String,
      required: true
    },
    adFormat: {
      type: String,
      required: false,
      default: 'auto'
    },
    adStyle: {
      type: String,
      required: false,
      default: 'display: block'
    }
  },
  mounted: function() {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  }
});

Vue.component('game-card', {
  props: ['game'],
  template: '#game-card-template',
  data: function() {
    return {
      flipped: false,
      gameClasses: null
    };
  },
  computed: {

    setCardImage: function() {
      var output = '';

      if(this.game.media[1]) {
        output += this.game.media[1].link.split('/').reverse()[0];
      } else {
        output += 'default';
      }

      return 'http://2017.e3recap.com/images/game-cards/' + output + '.jpg';
    },

    formattedDate: function() {
      var game = this.game;
      var formatStr = '';

      switch(game.date_display) {
        case 1:
          formatStr = 'MMMM D, YYYY';
          break;
        case 2:
          formatStr = 'MMMM YYYY';
          break;
        case 3:
          formatStr = 'YYYY';
          break;
        case 4:
          formatStr = '[Q]Q YYYY';
          break;
      }

      var d = moment.utc(game.date).format(formatStr);

      return (d === 'Invalid date') ? '' : d;
    }
  },
  methods: {
    playMedia: function(index) {
      this.$emit('play-media', { game: this.game, mediaIndex: index });
    },
    flipCard: function() {

      ga('send', 'event', {
        eventCategory: 'Game',
        eventAction: 'click',
        eventLabel: this.game.title,
        transport: 'beacon'
      });

      this.flipped = !this.flipped;
    }
  },
  created: function() {
    var cssString = '';

     cssString += this.game.conference ? ' ' + shared.cssFriendly(this.game.conference.title) : '';

    this.gameClasses = cssString;
  }
});





Vue.component('nav-item', {
  props: ['data'],
  template: '#nav-item-template'
});


Vue.component('bulk-delete-checkbox', {
  props: ['game', 'to-be-deleted'],
  template: '#bulk-delete-checkbox-template',
  data: function() {
    return { value: false };
  },
  methods: {
    addToBulkDelete: function(game) {
      // console.log(game);
      this.$emit('add-to-bulk-delete', { game: game, checked: this.value });
    }
  },
  watch: {
    toBeDeleted: function(newValue) {
      if(newValue.length === 0) {
        this.value = false;
      }
    }
  }
});


Vue.component('filter-group', {
  props: ['filters', 'filterGroupName'],
  template: '#filter-group',
  data: function() {
    return { activeFilters: [] };
  },
  methods: {
    toggleFilter: function(filter) {
      var filterIndex = this.activeFilters.indexOf(filter);

      if(filterIndex === -1) {
        this.activeFilters.push(filter);
      } else {
        this.activeFilters.splice(filterIndex, 1);
      }

      this.$emit('update-filters', { name: this.filterGroupName, filters: this.activeFilters });
    }
  }
});

Vue.component('game-filter', {
  props: ['filter'],
  data: function() {
    return { selected: false };
  },
  template: '#game-filter-template',
  methods: {
    toggleFilter: function() {

      ga('send', 'event', {
        eventCategory: 'Filter',
        eventAction: 'click',
        eventLabel: this.filter.title,
        transport: 'beacon'
      });

      this.$emit('toggle-filter', this.filter._id);
    }
  }
});


var router = new VueRouter({

  mode: 'history', // removes hash from url

  routes: [
    {
      path: '/',
      component: {
        mixins: [ dataMixin, allRoutesMixin, utilityMixin ],
        template: '#home-template',
        data: function() {
          return {
            formValues: {
              conferences: null
            },
            filterGroups: null,
            search: '',
            games: null,
            activeFilters: [],
            sort: null,
            dropdowns: {
              conferenceSchedule: false,
              sorting: false,
              filters: false
            },
            blockWidth: 366.3,
            wrapperWidth: null,
            maxBoxPerRow: null,
            gridStyles: {
              width: null
            },
            videoSrc: null,
          };
        },

        methods: {

          clearFilters: function() {

            $('.game-filter-checkbox').prop('checked', false);

            this.$refs.key.forEach(function(component) {
              component.activeFilters = [];
            });

            this.search = '';
            this.activeFilters = [];
            this.sort = null;

            this.updateGames();
          },

          toggleMedia: function(data) {
            this.videoSrc = (data ? data.game.media[data.mediaIndex].link : data) + '?rel=0&autoplay=1';
          },

          updateSearch: function() {
            // console.log('event emitted', this.search);
            var vm = this;

            if(shared.searchTimeout) clearTimeout(shared.searchTimeout);

            if(this.search.length >= 2 || this.search.length === 0) {
              shared.searchTimeout = setTimeout(function() {
                vm.updateGames();
              }, shared.keyTimeout);
            }
          },

          updateFilters: function(filter) {
            var activeFilters = this.activeFilters;

            for(var i = 0; i < activeFilters.length; i++) {
              if(activeFilters[i].name === filter.name) {
                activeFilters.splice(i, 1);
              }
            }

            activeFilters.push(filter);
            this.updateGames();
          },

          getWindowWidth: function(event) {
            this.wrapperWidth = $(this.$el).width();
            this.maxBoxPerRow = Math.floor(this.wrapperWidth / this.blockWidth);
            this.gridStyles.width = (this.maxBoxPerRow * this.blockWidth) + 'px';
          }
        },

        mounted: function() {
          window.addEventListener('resize', this.getWindowWidth);
          this.getWindowWidth();
        },

        beforeCreate: function() {
          // console.log('before create vue');

          var vm = this;

          axios
            .get('/api/all')
            .then(function(response) {
              // console.log(response);

              var data = response.data;

              vm.games = data.games;

              vm.formValues = {
                conferences: data.conferences,
                mediaTypes: data.mediaTypes,
                platforms: data.platforms,
                days: shared.data.days,
                dateFormats: shared.data.dateFormats
              };

              vm.filterGroups = {
                conferences: data.conferences,
                platforms: data.platforms,
                days: shared.data.days,
                release: shared.data.release,
                exclusives: shared.data.exclusives,
                vr: shared.data.vr
              };
            })
            .catch(function(error) {
              console.log(error, error.response);
            });
        }
      }
    },
    {
      path: '/watch',
      component: {
        mixins: [allRoutesMixin, utilityMixin],
        template: '#watch-template',
        data: function() {
          return {
            conferences: null,
            streams: null,
            dropdowns: {
              schedule: false,
              rewatch: false,
              source: false
            },
            activeStream: {
              icon: null,
              link: null,
              live: false,
              title: null
            },
            liveThread: null
          };
        },
        methods: {
          streamIcons: function(stream) {
            return 'e3 e3-' + stream.icon;
          }
        },
        beforeCreate: function() {
          var vm = this;

          axios
            .get('/api/all')
            .then(function(res) {
              // console.log(res);
              vm.conferences = res.data.conferences;
              vm.streams = res.data.streams;

              var liveStreams = [];
              var rewatchStreams = [];

              res.data.streams.forEach(function(stream) {
                var target = stream.live ? liveStreams : rewatchStreams;
                target.push(stream);

                if(stream.thread && !vm.liveThread) vm.liveThread = stream.link;
              });

              vm.activeStream = liveStreams.length ? liveStreams[0] : rewatchStreams[0];
            });
        }
      }
    },
    {
      path: '/about',
      component: {
        mixins: [allRoutesMixin],
        template: '#about-template'
      }
    },
    {
      path: '/login',
      component: {
        mixins: [allRoutesMixin, formMixin],
        template: '#login-template',
        data: function() {
          return {
            formFields: {
              password: shared.tempPassword
            },
            error: ''
          }
        },
        methods: {
          authenticate: function() {
            // console.log('authenticating');

            var vm = this;

            axios
              .post('/login', vm.formFields)
              .then(function(res) {

                if(res.data) { // passwords match

                  vm.$router.push('/admin');

                } else { // passwords do not match

                  vm.error = 'Incorrect password; please try again.';
                }
              })
              .catch(vm.errorHandler.bind(vm));
          }
        },
        beforeRouteEnter: function(to, from, next) {

          axios
            .get('/api/auth')
            .then(function(res) {
              // console.log(res);
              next('/admin');
            })
            .catch(function(error) {
              // console.log(error);
              next();
            });
        }
      }
    },
    {
      path: '/install',
      component: {
        mixins: [formMixin],
        template: '#install-template',
        data: function() {
          return {
            installForm: {
              email: 'operations@novasans.co',
              password: shared.tempPassword,
              confirm: shared.tempPassword
            },
            error: '',
            errors: []
          };
        },

        methods: {

          install: function() {
            var vm = this;

            axios
              .post('/api/init', this.installForm)
              .then(function(res) {
                if(res.data) vm.$router.push('/login');
              });
          }
        },

        beforeRouteEnter: function(to, from, next) {

          axios
            .get('/api/init')
            .then(function(res) {

              if(res.data) {
                next('/');
              } else {
                next();
              }
            });
        }
      }
    },
    {
      path: '/admin',
      component: {
        mixins: [allRoutesMixin],
        props: ['games', 'formValues', 'filterGroups'],
        template: '#admin-template',

        created: function() {
          // console.log('created event emitted');
          this.$emit('admin-nav-items');
        },

        beforeRouteEnter: function(to, from, next) {
          // console.log('checking for user');
          var vm = this;

          axios
            .get('/api/admin')
            .then(function(res) {
              next();
            })
            .catch(function(error) {
              next('/');
            });
        },

        beforeCreate: function() {
          this.$root.isAdmin = true;
        }
      },
      children: [
        {
          path: '',
          component: {
            mixins: [ dataMixin ],
            template: '#admin-home-template',
            data: function() {
              return {
                bulkDeleteIds: [],
                games: [],
                error: '',
                success: ''
              };
            },
            methods: {
              addToBulkDeleteArray: function(values) {
                if(values.checked) {
                  this.bulkDeleteIds.push(values.game._id);
                } else {
                  this.bulkDeleteIds.splice(this.bulkDeleteIds.indexOf(values.game._id), 1);
                }
              },

              bulkDelete: function() {
                // console.log('delete request made');
                var vm = this;

                if(vm.bulkDeleteIds.length) {

                  axios
                    .post('/api/games', vm.bulkDeleteIds)
                    .then(function(response) {
                      console.log('games deleted');
                      vm.bulkDeleteIds = [];
                      vm.updateGames();
                      vm.success = 'Games successfully deleted!';
                    })
                    .catch(function(error) {
                      vm.error = 'The games could not be deleted at this time!';
                    });
                }
              }
            },

            beforeCreate: function() {
              var vm = this;

              axios
                .get('/api/games')
                .then(function(response) {
                  // console.log(response);
                  vm.games = response.data;
                });
            }
          }
        },
        {
          path: 'add-game',
          component: {
            data: function() {
              return addGameFormReset(shared.addGame);
            },
            template: '#add-game-template',
            mixins: [ addAndEditMixin, formMixin ],
            methods: {
              submitGame: function() {
                var vm = this;

                vm.success = '';
                vm.errors = [];

                axios
                  .post('/api/game', this.formFields)
                  .then(function(response) {
                    // console.log(response);

                    vm.$data = addGameFormReset(shared.addGame);
                    vm.success = 'Game successfully added!';
                    vm.openDrawer();

                  })
                  .catch(this.errorHandler.bind(this));
              },

              openDrawer: function() {

                this.formFields.media.push({
                  type: null,
                  link: null,
                  external: false
                });
              }
            },

            mounted: function() {
              var vm = this;

              axios
                .get('/api/form-values')
                .then(function(response) {
                  // console.log(response);
                  vm.conferences = response.data.conferences;
                  vm.mediaTypes = response.data.mediaTypes;
                  vm.platforms = response.data.platforms;
                  vm.days = shared.data.days;
                  vm.dateFormats = shared.data.dateFormats;
                  vm.vr = shared.data.vr;

                  var releases;

                  if(response.data.release) {
                    response.data.release.slice().map(vm.mapCallback);
                  } else {
                    releases = [];
                  }

                  vm.platforms.forEach(function(platform) {
                    var state = (releases.indexOf(platform._id) !== -1) ? true : false;
                    vm.releases.push(state);
                  });

                  vm.openDrawer();
                });
            }
          }
        },
        {
          path: 'edit-game/:id',
          component: {
            template: '#add-game-template',
            mixins: [ addAndEditMixin, formMixin ],
            data: function() {
              return addGameFormReset(shared.updateGame);
            },
            computed: {
              gameId: function() {
                return window.location.pathname.split('/').reverse()[0];
              }
            },
            methods: {

              submitGame: function() {
                var vm = this;

                vm.errors = [];

                console.log(vm.formFields);

                axios
                  .post('/api/game/' + vm.gameId, vm.formFields)
                  .then(function(response) {
                    // console.log(response);
                    vm.success = 'Game updated successfully!';
                    vm.updateGame();
                  })
                  .catch(vm.errorHandler.bind(vm));
              },

              updateGame: function() {
                // console.log(this.$data);
                var vm = this;

                axios
                  .get('/api/game/' + vm.gameId)
                  .then(function(response) {
                    // console.log(response.data);
                    vm.formFields = response.data;
                    vm.title = 'Now editing: ' + response.data.title;

                    var releases = response.data.release.slice().map(vm.mapCallback);

                    vm.platforms.forEach(function(platform) {
                      var state = (releases.indexOf(platform._id) !== -1) ? true : false;
                      vm.releases.push(state);
                    });
                  });
              }
            },

            mounted: function() {
              // console.log(this.$data);

              var vm = this;

              axios
                .get('/api/form-values')
                .then(function(response) {
                  // console.log(response);
                  vm.conferences = response.data.conferences;
                  vm.mediaTypes = response.data.mediaTypes;
                  vm.platforms = response.data.platforms;
                  vm.days = shared.data.days;
                  vm.dateFormats = shared.data.dateFormats;
                  vm.vr = shared.data.vr;

                  vm.updateGame.call(vm);
                });
            }
          }
        },
        {
          path: 'streams',
          component: {
            mixins: [ formMixin ],
            template: '#streams-template',
            data: function() {
              return {
                streams: null,
                stream: streamFormReset(),
                errors: [ ],
                success: ''
              };
            },
            methods: {
              submitStream: function() {

                var vm = this;

                vm.errors = [];

                axios
                  .post('/api/stream', vm.stream)
                  .then(function(response) {
                    console.log(response);
                    vm.success = 'Stream successfully added!';
                    vm.stream = streamFormReset();
                    vm.updateStreams();
                  })
                  .catch(vm.errorHandler.bind(vm));
              },
              updateStreams: function() {

                var vm = this;

                axios
                  .get('/api/streams')
                  .then(function(response) {
                    // console.log(response);
                    vm.streams = response.data;
                  });
              },

              updateStream: function(stream) {
                var vm = this;

                vm.success = '';

                axios
                  .put('/api/stream/' + stream._id, stream)
                  .then(function(response) {
                    console.log(response);
                    vm.success = 'Stream updated!';
                  });
              },

              deleteStream: function(id) {
                var vm = this;

                axios
                  .delete('/api/stream/' + id)
                  .then(function(response) {
                    console.log(response);
                    vm.success = 'Stream deleted!';
                    vm.updateStreams();
                  });
              }
            },
            created: function() {
              this.updateStreams();
            }
          }
        },

        {
          path: 'conferences',
          component: {
            template: '#conferences-template',
            data: function() {
              return {
                conferences: null
              };
            },
            computed: {
              now: function() {
                return shared.getTimestamp();
              }
            },
            methods: {
              status: function(index) {
                var conference = this.conferences[index];
                var startTime = shared.getTimestamp(conference.start_time);
                var endTime = shared.getTimestamp(conference.end_time);

                if(startTime > this.now) {
                  return 'Coming Up';

                } else if(endTime && this.now > endTime) {
                  return 'Ended';

                } else if(this.now > startTime) {
                  return 'Now Live';
                }
              },

              autofillTime: function(index) {
                var conference = this.conferences[index];

                if(!conference.end_time) {
                  conference.end_time = moment();
                }
              },

              saveConference: function(conference) {

                axios
                  .put('/api/conference/' + conference._id, conference)
                  .then(function(response) {
                    console.log(response);
                  });
              }
            },
            beforeCreate: function() {

              var vm = this;

              axios
                .get('/api/conferences')
                .then(function(response) {
                  // console.log(response);
                  vm.conferences = response.data;
                });
            }
          }
        },
        {
          path: 'settings',
          component: {
            mixins: [formMixin],
            template: '#settings-template',
            data: function() {
              return {
                formFields: {
                  email: '',
                  password: '',
                  confirm: ''
                },
                errors: [],
                error: '',
                success: ''
              };
            },
            methods: {
              updateSettings: function() {
                var vm = this;

                vm.errors = [];
                vm.error = '';
                vm.success = '';

                axios
                  .put('/api/admin', vm.formFields)
                  .then(function(res) {
                    vm.success = 'Password updated!';
                    vm.formFields.password = '';
                    vm.formFields.confirm = '';
                  })
                  .catch(vm.errorHandler.bind(vm));
              }
            },
            beforeCreate: function() {
              console.log('before create');

              var vm = this;

              axios
                .get('/api/admin')
                .then(function(res) {
                  vm.formFields.email = res.data.email;
                });
            }
          }
        },
      ]
    },
    { // catch all
      path: '*',
      component: {
        mixins: [allRoutesMixin],
        template: '#notfound-template'
      }
    }
  ],
});


var app = new Vue({

  el: '#app',

  router: router,

  mixins: [utilityMixin],

  data: {
    isAdmin: false,
    dropdowns: {
      social: false
    }
  },

  methods: {

    getLocation: function() {
      return window.location.pathname;
    },

    logout: function() {
      // console.log('logout attempted');

      var vm = this;

      axios
        .get('/api/logout')
        .then(function(response) {
          // console.log(response);
          if(response.data) {
            vm.$router.go('/');

          } else {
            vm.$router.go('/admin');
          }
        })
        .catch(function(error) {
          // console.log(error);
          vm.$router.go('/admin');
        });
    }
  }
});


function addGameFormReset(options) {
  options.submitLabel = !options.submitLabel ? '' : options.submitLabel;
  options.title = !options.title ? '' : options.title;

  return {
    formFields: {
      title: '',
      developer: '',
      genre: '',
      info: '',
      day: null,
      conference: null,
      date: null,
      date_display: 1,
      exclusive: false,
      release: [ ],
      vr: null,
      media: [ ]
    },
    conferences: null,
    mediaTypes: null,
    platforms: null,
    days: null,
    dateFormats: null,
    errors: [ ],
    success: '',
    vr: null,
    title: options.title,
    submitLabel: options.submitLabel,
    releases: []
  };
}

function streamFormReset() {
  return {
    link: null,
    title: null,
    live: false,
    icon: null,
    thread: true
  };
}
