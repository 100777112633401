$(function() {

	var siteBg = [
    'battlefront-2.jpg',
    'call-of-duty-ww2-bg.jpg',
    'days-gone.jpg',
    'death-stranding.jpg',
    'destiny-2.jpg',
    'middle-earth-shadow-of-war.jpg',
    'spider-man.jpg','splatoon-2.jpg',
    'state-of-decay-2.jpg',
    'super-mario-odyssey.jpg'
	];

	var siteBgResult = siteBg[randomNumber(0, siteBg.length)];

	$('#main-bg').css('background-image', 'url(/images/backgrounds/' + siteBgResult + ')').addClass('animate');

  function randomNumber(min, max) {
    return Math.floor(Math.random() * max);
  }
});
